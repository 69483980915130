.projects-container {
    margin: 10rem auto 0 auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 70%;
    justify-content: center;
}

@media (max-width: 1000px) {
    .projects-container {
        grid-template-columns: 1fr; /* Stacks items in a single column on smaller screens */
        width: 80%; /* Make the container take up full width on smaller screens */
    }

    .project-title{ /* DO NOT TOUCH*/
        font-size: 1.7em;
    }
}

.project-item {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: auto auto 0 auto;
    margin-bottom: 5rem;
    border: 2px solid #e0e0e0; /* Add border */
    border-radius: 10px; /* Rounded corners */
    transition: transform 0.3s ease-in-out, box-shadow 0.5s ease-in-out; /* Smooth hover effect */

    opacity: 0;
    transform: translateY(20px); /* Start 20px below */
    transition: opacity 0.8s ease, transform 0.8s ease;
}

.project-item a{
    text-decoration: none;
}

.project-item.visible {
    opacity: 1;
    transform: translateY(0); /* Move to original position */
}

.project-item:hover {
    transform: scale(1.04); /* Slightly expand on hover */
    box-shadow: 0 4px 15px rgba(98, 168, 249, 0.5);
    cursor: pointer;
}

.project-title{ /* DO NOT TOUCH*/
    margin-bottom: 0.5rem;
    margin-right: 1rem;
    text-decoration: none; /* Remove underline */
}

.project-image { /* DO NOT TOUCH */
    width: 100%;
    height: auto;
    border-radius: 1rem;
    padding: 1rem;
    object-fit: cover;
    overflow: hidden;
}

.project-image-drone { /* DO NOT TOUCH */
    width: 100%;
    height: auto;
    border-radius: 1rem;
    padding: 1rem;
    object-fit: cover;
    overflow: hidden;
}


.description-text-project { /* DO NOT TOUCH*/
    font-size: 1.2em;
    color: #E3E3DD;
    font-family: 'Assistant', sans-serif;
    min-width: 80%;
    text-align: left;
    line-height: 1.4;
    margin-right: 1.2rem;
    margin-bottom: 0.6rem;
    word-break: break-word; /* Break words when they exceed the container */
    hyphens: auto; 
    margin-left: 1.25rem;
}

.github-clickable {
    color: #E3E3DD;
    font-family: 'Assistant', sans-serif;
    max-width: 100%;
    text-align: left;
    line-height: 1.5;
    margin-right: 1.2rem;
    word-break: break-word; /* Break words when they exceed the container */
    hyphens: auto; 
    transition: background-color 0.3s ease, color 0.3s ease;
}

.github-clickable:hover {
    background-color: #62A8F9;
    color: #151415;
  }

  .github-clickable-embed {
    color: #E3E3DD;
    font-family: 'Assistant', sans-serif;
    max-width: 100%;
    text-align: left;
    line-height: 1.5;
    word-break: break-word; /* Break words when they exceed the container */
    hyphens: auto; 
    transition: background-color 0.3s ease, color 0.3s ease;
}

.github-clickable-embed:hover {
    background-color: #62A8F9;
    color: #151415;
  }

.project-link {
    color: #007bff;
    text-decoration: none;
}

.project-link:hover {
    text-decoration: underline;
}

img {
    border-radius: 10px; 
}

/* Fade-in from bottom animation */
@keyframes fadeInUp {
    from {
      opacity: 0;          /* Fully transparent */
      transform: translateY(20px); /* Move 20px from the bottom */
    }
    to {
      opacity: 1;          /* Fully visible */
      transform: translateY(0);    /* Back to the original position */
    }
}

.content-title{
    font-size: 3rem;
    text-align:left;
    margin-left: 0;
}


