.Resume {
    height: 100%;
}

.fade-in {
    opacity: 0;
    transform: translateY(10px); /* Starts 30px below its final position */
    transition: opacity 0.5s ease-in-out, transform 0.3s ease-in-out; /* Both opacity and transform will transition */
  }

  .resume-header {
    display: flex;
    position: relative; /* Fixes the header at the top */
    top: 0; /* Ensures it is at the top of the page */
    justify-content: space-between;
    padding: 10px 20px;
    border-bottom: 1px solid #E9E9E9;
    padding-top: 1.8rem;
    padding-bottom: 1.8rem;
    background-color: #151415;
    z-index: 1000; /* Ensures the header stays above other content */
  }

  .resume-container {
    width: 100%;
    margin: 0 auto;
    margin-top: 2rem;
    display: flex; /* Use flexbox */
    justify-content: center; /* Center the iframe */
    align-items: flex-start; /* Align iframe to the top */
    overflow: hidden;
}

.resume-iframe {
    width: 58%; 
    height: 100vh;
    border: none;
}

@media (max-width: 768px) {
    .resume-container{
        width: 100%
    }

    .resume-header {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .resume-iframe{
        width: 100vh;
    }
}

iframe {
    border: none; 
    height: 100vh;
    overflow-clip-margin: content-box !important;
    overflow: clip !important;
}

