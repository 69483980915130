.spotifinder-content-container {
    margin: 10rem auto 0 auto;
    justify-content: center;
    width: 100%; /* Use full width */
}

/* Header */
.spotifinder-content-container-header {
    position: relative;
    padding-bottom: 1rem;
    width: 54rem;
    margin: 0 auto; /* Center horizontally */
}
  
/* Blue rectangle */
.spotifinder-content-container-header::after {
    content: "";
    display: block;
    position: absolute;
    width: 50px;
    height: 6px;
    background-color: #62A8F9;
    border-radius: 12px;
    bottom: 0px;
}

/* Content detail */
.content-container-detail {
    width: 54rem;
    margin: 0 auto;
    opacity: 0;
    animation: fadeInUp 0.8s ease forwards;
    animation-delay: 0.2s;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: left;
    margin-top: 2rem;
}

/* Description Text */
.spotifinder-description-text-project-detail {
    color: #E3E3DD;
    font-family: 'Assistant', sans-serif;
    width: 100%; /* Full width */
    text-align: left;
    line-height: 1.5;
    margin: 0 0 0.3rem 0; /* Remove negative margins */
    word-break: break-word;
    font-size: 1.1rem;
}

.spotifinder-description-text-project-detail-caption {
    color: #E3E3DD;
    font-family: 'Assistant', sans-serif;
    width: 100%; /* Full width */
    line-height: 1.5;
    margin: 0 0 0.3rem 0; /* Remove negative margins */
    word-break: break-word;
    font-size: 1.1rem;
    margin-bottom: 2rem;
}

/* Subtitle */
.spotifinder-description-text-project-subtitle {
    font-size: 1.2em;
    color: #E3E3DD;
    font-family: 'Assistant', sans-serif;
    width: 100%; /* Full width */
    text-align: left;
    line-height: 1.4;
    margin-bottom: 0.6rem;
    word-break: break-word;
    hyphens: auto;
}

/* Subtitle Container */
.spotifinder-subtitle-container {
    width: 100%; /* Full width */
    text-align: left;
    height: 100%;
}

/* Subtitle */
.spotifinder-subtitle {
    margin: 0;
    text-align: left;
}

.spotifinder-subtitle-tech {
    margin: 0;
    text-align: left;
    font-size: 1.6em;
}

.spotifinder-image{
    border-radius: 0;
    scale: 95%;
    margin-bottom: -0.6rem;
}

.spotifinder-image-drone{
    border-radius: 0;
    scale: 80%;
    margin-bottom: -3rem;
    margin-top: -3rem;
}

.spotifinder-content-divider {
    width: 50px;
    height: 6px;
    background-color: #62A8F9;
    border-radius: 12px;
    bottom: 0px;
}
/* Responsive Styles */
@media (max-width: 1000px) {
    .spotifinder-content-container-header,
    .content-container-detail {
        width: 80%; /* Make the container full width on smaller screens */
    }

    .spotifinder-description-text-project-detail,
    .spotifinder-description-text-project-subtitle {
        width: 100%; /* Full width for text */
    }

    .spotifinder-subtitle {
        font-size: 2rem;
        text-align: left;
    }

    .subtitle-container {
        text-align: center;
        width: 100%;
    }
    .spotifinder-image-drone{
        border-radius: 0;
        scale: 100%;
        margin-bottom: 0rem;
        margin-top: 0rem;
    }
}
 .github-gist{
    width: 80%;
    margin: 0 auto; /* Center horizontally */
 }